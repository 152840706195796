// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  API: '',
  offShoreAPI:'https://us-central1-customer-service-portal-325207.cloudfunctions.net/',
  devShoreAPI:'https://us-west2-center-store-inventory-dev.cloudfunctions.net/',
  uatShoreAPI:'https://us-west2-center-store-inventory-dev.cloudfunctions.net/',
  prodShoreAPI:'https://us-west2-center-store-inventory-prod.cloudfunctions.net/',
  ProdSiteURL:'csinventory.savemart.com',
  DevSiteURL:'csinventory-dev.savemart.com',
  UATSiteURL:'csinventory-dev.savemart.com',
  OffshoreSiteURL:'newitem-dev2.savemart.com', 
  redirectURLSSODev:'https://desktop.pingone.com/savemart/url?source=application&url=https%3A%2F%2Fsso.connect.pingidentity.com%2Fsso%2Fsp%2Finitsso%3Fsaasid%3De9180e2c-86b1-4cb7-84ee-9c2de78e70e7%26idpid%3Dcsinventory-dev.savemart.com&title=IDP%20Connection&applicationtype=APPLICATION_DEFAULT&saasid=e9180e2c-86b1-4cb7-84ee-9c2de78e70e7&newDock=true',
  redirectURLSSOProd:'https://desktop.pingone.com/savemart/url?source=application&url=https%3A%2F%2Fsso.connect.pingidentity.com%2Fsso%2Fsp%2Finitsso%3Fsaasid%3Dad0d8fdb-a757-4512-b9b7-5b0e808120da%26idpid%3Dcsinventory.savemart.com&title=IDP%20Connection&applicationtype=APPLICATION_DEFAULT&saasid=ad0d8fdb-a757-4512-b9b7-5b0e808120da&newDock=true',
  
  verifyCSILogin: 'verifyCSILogin',
  getCSILibraryValues:'getCSILibraryValues',
  GetCSIInventoryDetailsByGroup:'GetCSIInventoryDetailsByGroup',
  GetCSIInventoryDashboardDetails:'GetCSIInventoryDashboardDetails',
  AddUpdateCSIDepartment: 'AddUpdateCSIDepartment',
  GetCSIInventoryDetailsByGroups:'GetCSIInventoryDetailsByGroups',
  AddUpdateCSIUsers:'AddUpdateCSIUsers',
  AddUpdateDeleteCSIVendor:'AddUpdateDeleteCSIVendor',
  AddUpdateDeleteCSIVendorSchedule:'AddUpdateDeleteCSIVendorSchedule',
  uploadCSIVendorFiles: 'uploadCSIVendorFiles',
  getCSIReportData:'getCSIReportData',
  GetCSIInventoryReportGroupDetails:'GetCSIInventoryReportGroupDetails',
  CreateInventoryCorrections :'createInventoryCorrections',
  ApproveRejectInventoryCorrections :'ApproveRejectInventoryCorrections',
  CSIResetUserPassword :'CSIResetUserPassword',
  AddCSINewUserRegister :'AddCSINewUserRegister',
  AddUpdateCSIUPCCorrection :'AddUpdateCSIUPCCorrection',
  authenticatePing : 'AuthenticateWithPing',
  GetCSIInventoryDetails:'GetCSIInventoryDetails',
  GetCostSummary:'getCostSummary',
  GetUPCInfo:'getUPCInfo',
  submitMultipleInvCorrections:'submitMultipleInvCorrections',
  GetExcelCostSummary:"getExcelCostSummaryReport",
  getCSIUPCDetails:"getCSIUPCDetails",
  GetCSIInventorydetailsExcel:"GetCSIInventorydetailsExcel",
  GetRawInventoryExcelDownload:"getRawInventoryExcelDownload"

};
